<template>
    <div>
        <v-select
        v-model="txt_label"
        :items="roadtripLabels"
        item-text="item2"
        item-value="item1"
        placeholder="Label"
        auto-select-first
        dense></v-select>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'RoadtripLabelDropdown',
    props: 
    {
        label: Number
    },
    beforeMount()
    {
        this.txt_label = this.label;
    },
    data() {
        return {
            txt_label: {},
        }
    },
    computed: {
        ...mapState({
            roadtripLabels: state => state.menuHelper.roadtripLabels
        })
    },
    watch: {
        label: {
            deep: true,
            handler() {
                this.txt_label = this.label;
            }
        },
        txt_label: 'onValueChanged'
    },
    methods:
    {
        onValueChanged()
        {
            this.$emit('changed', this.txt_label);
        }
    }
}
</script>