<template>
    <span :class="templateClass" class="editable-input">
        <span v-if="!editText">
            <img class="icon edit-icon mt-2 ml-2" :src="editIcon" :alt="altText" @click="editTitle()">
            <div v-html="text"/> 
        </span>
        <div v-else class="mt-2">
            <v-icon @click="editTitle()" class="mt-n8 float-right">
                mdi-check
            </v-icon>
            <tiptap-vuetify
                v-model="text"
                :toolbar-attributes="{ color: '#333333' }"
                :extensions="extensions" />
            
        </div>
    </span>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

export default {
    name: 'EditableTextEditor',
    props: 
    {
        textInput: String,
        altText: String,
        templateClass: String
    },
    components: {
        TiptapVuetify
    },
    beforeMount()
    {
        this.text = this.textInput;
    },
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        editText: false,
        text: '',
        // declare extensions for rich text editor
        extensions: [
            Bold,
            Underline,
            Strike,
            Italic,
            Code,
            Link,
            [Heading, {
                options: {
                levels: [1, 2, 3, 4, 5, 6]
                }
            }],
            HorizontalRule,
            HardBreak,
            Blockquote,
            Paragraph,
            ListItem,
            BulletList,
            OrderedList,
            History,
        ],
    }),
    watch: {
        textInput: {
            deep: true,
            handler() {
                this.text = this.textInput;
            }
        },
    },
    methods:
    {
        editTitle()
        {
            this.editText = !this.editText;
            this.$nextTick(() => {
                const title = this.$refs.title;

                if (title)
                {
                    title.focus();
                }
                
            })

            if (!this.editText)
            {
                this.$emit('updated', this.formatHtml());
            }
        },
        formatHtml()
        {
            // Known issue for tiptap (extending ProseMirror) which icludes <p> inside list item
            // this regex will remove the <p> inside lists.
            return this.text.replaceAll(/<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi, "<li>$1<$2$3>");
        }
    }
}
</script>
<style>
.editable-input .edit-icon {
   width: 20px; 
   height: 20px; 
}
</style>
