export default {
    methods: 
    {
        checkNewRoadtrip(roadtrip)
        {
            let errorMessage =  this.checkProductName(roadtrip.productInformation.name);
            errorMessage = errorMessage + this.checkProductText(roadtrip.productInformation.productText);
            errorMessage = errorMessage + this.checkPackageDescription(roadtrip.productInformation.packageDescription);
            errorMessage = errorMessage + this.checkProductPrices(roadtrip.productInformation);
            errorMessage = errorMessage + this.checkOsmAndInfo(roadtrip.baseGPXInformation.osmAndInformation);
            errorMessage = errorMessage + this.checkParticipantsItinerary(roadtrip.participantsItinerary);
            errorMessage = errorMessage + this.checkWhatsApp(roadtrip.whatsAppURL);
           
            // errorMessage = errorMessage + this.checkOrganizationItinerary(roadtrip.organizationItinerary);

            errorMessage = errorMessage == "" ? "" : `<p>Los de volgende problemen op:</p><ul>${errorMessage}</ul>`
            return errorMessage;
        },
        checkProductName(name)
        {
            let errorMessage = "";
            if (!name.trim() || name == "Nieuwe roadtrip")
            {
                errorMessage = "<li>Zorg ervoor dat het nieuwe product een naam heeft.</li>"
            }

            return errorMessage;
        },
        checkProductText(text)
        {
            let errorMessage = "";
            var div = document.createElement("div");
            div.innerHTML = text;
            if (!div.innerText.trim() || div.innerText == "Voeg producttekst toe.")
            {
                errorMessage = "<li>Zorg ervoor dat het nieuwe product een tekst heeft.</li>"
            }

            return errorMessage;
        },
        checkProductPrices(info)
        {
            let errorMessage = "";
            if(!info.mainPrice)
            {
                errorMessage = "<li>Basispakket-prijs mag niet €0 zijn.</li>"
            }

            if(!info.passengerPrice)
            {
                errorMessage = errorMessage + "<li>Passagier-prijs mag niet €0 zijn.</li>"
            }

            return errorMessage;
        },
        checkPackageDescription(text)
        {
            let errorMessage = "";
            var div = document.createElement("div");
            div.innerHTML = text;
            if (!div.innerText.trim() || div.innerText == "Voeg pakketomschrijving toe.")
            {
                errorMessage = "<li>Zorg ervoor dat er een pakketomschrijving is.</li>"
            }

            return errorMessage;
        },
        checkOsmAndInfo(osmAnd)
        {
            let errorMessage = "";
            if (osmAnd.length == 0)
            {
                errorMessage = "<li>Vergeet niet OsmAnd-informatie in te vullen; elke roadtrip heeft gebieden waar wordt gereden. Deze dienen te worden gespecificeerd. Indien dit niet wordt gedaan wordt de informatie-mail niet correct geformat.</li>"
            }

            const pending = osmAnd.find(x => x.isPending);
            if(pending)
            {
                errorMessage = errorMessage + "<li>OsmAnd-informatie heeft wijzigingen in behandeling.</li>"
            }

            return errorMessage;
        },
        checkParticipantsItinerary(itinerary)
        {
            let errorMessage = "";
            const pending = itinerary.find(x => x.isPending);
            if(pending)
            {
                errorMessage = "<li>Planning Deelnemers heeft wijzigingen in behandeling.</li>"
            }

            if(itinerary.length == 0)
            {
                errorMessage = errorMessage + "<li>De roadtrip moet tenminste één locatie in de planning hebben.</li>"
            }

            return errorMessage;
        },
        checkOrganizationItinerary(itinerary)
        {
            let errorMessage = "";
            const pending = itinerary.find(x => x.isPending);
            if(pending)
            {
                errorMessage = "<li>Planning Organisatie heeft wijzigingen in behandeling.</li>"
            }

            return errorMessage;
        },
        // only for new roadtrips
        checkRoadtripPhotos(photos)
        {
            // must contain atleast 1 photo
            let errorMessage = "";
            if(photos.length <= 0)
            {
                errorMessage = "<li>Roadtrip moet minimaal 1 foto bevatten.</li>"
            }

            return errorMessage;
        },
        checkRoadtripImageSize(newImages)
        {
            let errorMessage = "";
            var totalSizeInMB = 0;
            newImages.forEach(image => {
                totalSizeInMB = totalSizeInMB + (image.size / (1024*1024));
            });

            // Calculation of MB from bytes has an offset of few bytes compared from raw image size.
            // Api allows 110MB, but let's keep it up to 100MB for new images allowed to be uploaded.
            if (totalSizeInMB > 100)
            {
                errorMessage = "<li>Totaal mogen de foto's maximaal 100 MB zijn. Kies minder foto's of comprimeer foto's die je wilt uploaden.</li>"
            }

            return errorMessage;
        },
        checkWhatsApp(link)
        {
            // must contain a gpx file
            let errorMessage = "";
            if(!link || !link.trim())
            {
                errorMessage = "<li>Roadtrip moet een WhatsApp-link hebben.</li>"
            }

            return errorMessage;
        },
        checkGPXFileChanges(gpx_file, gpx_file_changes)
        {
            let errorMessage = "";
            if (gpx_file == null || (gpx_file_changes != '' && gpx_file != null)) return errorMessage;
            else {
                errorMessage = "<li>GPX-wijzigingen moeten worden aangegeven.</li>"
            }

            return errorMessage;
        },
    }
}