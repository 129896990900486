<template>
    <div>
       <div v-for="(plan,index) in plans"
                :key="index">
                <PlanTemplate :indexNr="index" :plans="plans" />
        </div>
        <div class="new-planning">
            <v-expand-transition>
                <div v-show="pendingPlan" >
                    <PlanTemplate :indexNr="plans.length + 1" :plans="plans" />
                </div>
            </v-expand-transition>
            <div class="add-planning" :class="{ 'hide' :pendingPlan }">
                <v-btn color="primary" block class="mt-2 mb-5"  @click="addPlan" >
                    Voeg nieuwe locatie toe
                </v-btn>
            </div>
    </div>
</div>
 
</template>

<script>
import FormatMixin from "@/mixins/format.mixin";
import PlanTemplate from '@/components/admin/roadtrip/PlanTemplate'
export default {
    name: 'Planning',
    components: 
    {
        PlanTemplate,
    },
    data () {
        return {
            deleteIcon: require('@/assets/icons/delete.svg'),
        }
    },
    computed:
    {
        pendingPlan: function()
        {
            return this.plans.find(x => x.isPending);
        }
    },
    props: 
    {
        plans: Array
    },
    mixins: [FormatMixin],
    methods: {
        addPlan()
        {
            let plan = {
                isPending: true,
                locationType: 1,
                title: "",
                date: this.getToday(),
                googleMapsURL: "",
                arrivalTime: "", // datetime sent to api
                departureTime: "", // datetime sent to api
                
                // For display and selection
                arrivalTimeOnly: "00:00",
                departureTimeOnly: "00:00",
            };
            // check previous plan's date and end time
            if (this.plans.length > 0)
            {
                var previousPlan = this.plans[this.plans.length - 1];
                this.$set(plan, 'date', previousPlan.date);
                this.$set(plan, 'arrivalTime', previousPlan.departureTime);
                this.$set(plan, 'arrivalTimeOnly', previousPlan.departureTimeOnly);
            }


            this.plans.push(plan);
        },
    },
}
</script>

<style>
.new-planning .v-expansion-panel-content__wrap
{
    padding: 0px;
    background: #181717;
}

.hide
{
    visibility: hidden !important;
    opacity: 0 !important;
    height: 0 !important;
    overflow: hidden;
    transition: height 0ms 400ms, opacity 400ms 0ms !important;
}

.new-planning .add-planning
{
    visibility: visible;
    opacity:1;
    height: auto;
    transition: height 0ms 0ms, opacity 600ms 0ms;
}

</style>