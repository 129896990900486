import { Product } from './Product';
import { Photo } from './Photo';
import { StandardProductProperties } from './StandardProductProperties';
import { BaseGPXInformation } from './BaseGPXInformation';

export class Roadtrip {
    label = 0;
    whatsAppURL = '';
    images = [
        new Photo() // add default for upload image button in image carousel
    ];
    participantsItinerary = [];
    organizationItinerary = [];
    commonStepIds = [];
    roadtripId = '';
    productInformation = new Product();
    standardProductProperties = new StandardProductProperties();
    baseGPXInformation = new BaseGPXInformation();
}