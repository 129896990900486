<template>
    <div>
         <v-expand-transition>
        <div v-if="plan">
            <v-row>
                <v-col cols="12" class="location-header pb-0 pt-5">
                        <span>Locatie #{{ indexNr + 1 }}</span>
                        <v-spacer></v-spacer>
                        <div v-if="!isPending">
                            <img class="arrow-up-icon" :src="arrowUpIcon" alt="Move Up" @click="moveUp()" :class="{ 'hide' :indexNr == 0 }">
                            <img class="arrow-down-icon" :src="arrowDownIcon" alt="Move Down" @click="moveDown()" :class="{ 'hide' :disableMoveDown }" >
                            <img class="delete-icon" :src="deleteIcon" alt="Delete" @click="deletePlan()" />
                        </div>
                </v-col>
                <v-col cols="12" class="mb-0 pt-0">
                    <v-divider color="white"></v-divider>
                </v-col>
            </v-row>
                <v-form ref="form" class="ma-2"
                            v-model="valid"
                            lazy-validation>
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Titel *
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <v-text-field
                                :rules="[rules.required]"
                                required
                                :readonly="!isPending"
                                v-model="plan.title"
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Locatie Type *
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <RoadtripLocationDropdown :locationType="plan.locationType" :isReadOnly="!isPending" @changed="updateLocationType($event)"/>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Google Maps Link <span v-show="isGoogleMapsRequired">*</span>
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <v-text-field
                                :rules="[rules.requiredExcept(plan.googleMapsURL, !isGoogleMapsRequired)]"
                                required
                                :readonly="!isPending"
                                v-model="plan.googleMapsURL"
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Planning Datum *
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0 roadtrip-date">
                            <DatepickerFormatted 
                                :date="plan.date" 
                                :isRequired="true" 
                                :isReadOnly="!isPending" 
                                :isDisabled="!isPending"
                                @changed="plan.date = $event" 
                                :showIcon="true" :minDate="getToday()"/>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Aankomsttijd *
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0 roadtrip-date">
                            <v-menu
                                ref="menuArrival"
                                v-model="menuArrival"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="plan.arrival"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="plan.arrivalTimeOnly"
                                    :rules="[rules.required]"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    :disabled="!isPending"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-time-picker
                                format="24hr"
                                :allowed-minutes="allowedStep"
                                v-if="menuArrival"
                                v-model="plan.arrivalTimeOnly"
                                full-width
                                @click:minute="$refs.menuArrival.save(plan.arrivalTimeOnly)"
                                ></v-time-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Vertrektijd *
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0 roadtrip-date">

                            <v-menu
                                ref="menuDeparture"
                                v-model="menuDeparture"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="plan.departureTimeOnly"
                                transition="scale-transition"
                                offset-y
                                :readonly="!isPending"
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="plan.departureTimeOnly"
                                    :rules="[rules.required, rules.timeRangeValidation(plan.arrivalTimeOnly, plan.departureTimeOnly)]"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    :disabled="!isPending"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-time-picker
                                format="24hr"
                                :allowed-minutes="allowedStep"
                                v-if="menuDeparture"
                                v-model="plan.departureTimeOnly"
                                full-width
                                @click:minute="$refs.menuDeparture.save(plan.departureTimeOnly)"
                                ></v-time-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-form>

            <div v-show="isPending">
                    <v-btn color="primary" block class="mt-2"  @click="savePlan">
                    Opslaan
                </v-btn>
                <v-col cols="12 text-center">
                    Geen locatie toevoegen?
                    <span class="link" @click="removePlan()">Annuleren.</span>
                </v-col>
            </div>
        </div>
         </v-expand-transition>
    </div>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
import GlobalMixin from "@/mixins/global.mixin";
import FormatMixin from "@/mixins/format.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import RoadtripLocationDropdown from "@/components/common/options/RoadtripLocationDropdown";
import DatepickerFormatted from '@/components/common/DatepickerFormatted';
export default {
    name: 'PlanTemplate',
    components: {
        RoadtripLocationDropdown,
        DatepickerFormatted
    },
    data () {
        return {
            arrowUpIcon: require('@/assets/icons/arrow-up-thin.svg'),
            arrowDownIcon: require('@/assets/icons/arrow-down-thin.svg'),
            deleteIcon: require('@/assets/icons/delete.svg'),
            valid: true,

            menuArrival: false,
            menuDeparture: false,
            rijden: 'Rijden'
        }
    },
    props: 
    {
        plans: Array,
        indexNr: Number,

    },
    computed: {
        plan: function () {
            return this.plans[this.indexNr];
        },
        isPending: function()
        {
            var isPending = this.plan ? this.plan.isPending : true;
            return isPending;
        },
        disableMoveDown: function () {
            let last = this.plans.filter(x => x.isPending == false).pop();
            var index = this.plans.indexOf(last);
            return this.indexNr == index;
        },
        isGoogleMapsRequired()
        {
            // Require google maps link unless location type is 'Rijden'
            return this.getLocationTypeName(this.plan.locationType) != this.rijden;
        }
    },
    mixins: [GlobalMixin, DataMixin, MenuHelperMixin, FormatMixin],
    methods: {
        allowedStep: m => m % 5 === 0, // in 5 minutes interval
        savePlan()
        {
            const valid = this.$refs.form.validate()
            if (valid)
            {

                this.plan.arrivalTime = `${this.plan.date}T${this.plan.arrivalTimeOnly}:00`;
                this.plan.departureTime = `${this.plan.date}T${this.plan.departureTimeOnly}:00`;
                this.plan.isPending = false;
            }
        },
        updateLocationType(location)
        {
            this.$set(this.plan, 'locationType', location);
            // Update title to Rijden if location is Rijden.

            if (this.getLocationTypeName(location) == this.rijden)
            {
                this.$set(this.plan, 'title', this.rijden);
            }
            else
            {
                // if user changed from Rijden to different location type, remove title 
                if (this.plan.title == this.rijden)
                {
                    this.$set(this.plan, 'title', '');
                }
            }

        },
        removePlan()
        {
            this.plans.pop();
        },
        moveUp()
        {
            const newIndex = this.indexNr - 1;
            this.array_move(this.plans, this.indexNr, newIndex);
        },
        moveDown()
        {
            const newIndex = this.indexNr + 1;
            this.array_move(this.plans, this.indexNr, newIndex);
        },
        deletePlan()
        {
            this.$delete(this.plans, this.indexNr);
        }
    },
}
</script>

<style>
.location-header
{
    display: inline-flex;
    position: relative;
}

.location-header .hide
{
    width: 0px;
}

.arrow-up-icon,
.arrow-down-icon
{
    width: 25px;
    height: 25px;
}

.delete-icon 
{
    width: 20px;
    height: 20px;
    margin: 3px;
}

.roadtrip-date .v-input
{
    padding-top: 0px;
}
</style>