<template>
    <div>
        <div class="inner-container">
            <v-row no-gutters>
                <v-col>
                    <ImageUploadCarousel :images="roadtrip.images" @added="onImageAdded()" />
                </v-col>
            </v-row>
            <div class="px-2">
                <v-container>
                    <v-row no-gutters>
                        <v-col cols="8">
                            <EditableInput 
                            @updated="updateTitle"
                            :textInput="roadtrip.productInformation.name" 
                            :templateClass="`display-1 editable-header`" />
                        </v-col>
                        <v-col cols="4">
                            <RoadtripLabelDropdown :label="roadtrip.label" @changed="roadtrip.label = $event" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <div>
                            <v-switch color="green" class="mt-0"
                            :label="roadtrip.isPublished ? `Published` : `Private`"
                            v-model="roadtrip.isPublished"
                            inset></v-switch>
                        </div>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" class="ma-auto mb-4">
                            <span class="display-1">Productomschrijving:</span>
                            <EditableTextEditor
                                :textInput="roadtrip.productInformation.productText"
                                @updated="updateProductText" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto header-title mb-4">
                            Beschikbare tickets:
                        </v-col>
                        <v-col cols="6" class="ma-0 pa-0">
                            <v-text-field
                                hide-details="true"
                                @keypress="isNumber($event)"
                                v-model.number="roadtrip.productInformation.availableTickets"
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" class="pt-3">
                            <span class="display-1">Pakketomschrijving:</span>
                            <EditableTextEditor
                                :textInput="roadtrip.productInformation.packageDescription"
                                @updated="updatePakketDescription" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Basispakket *
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <v-text-field
                                prefix="€"
                                @keypress="isCurrency(mainPriceFormatted, $event)"
                                v-model="mainPriceFormatted"
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Passagier *
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <v-text-field
                                prefix="€"
                                @keypress="isCurrency(passengerPriceFormatted, $event)"
                                v-model="passengerPriceFormatted"
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Lunchpakket
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <v-text-field
                                prefix="€"
                                @keypress="isCurrency(lunchPriceFormatted, $event)"
                                v-model="lunchPriceFormatted"
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                     <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Dinner
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <v-text-field
                                prefix="€"
                                @keypress="isCurrency(dinnerPriceFormatted, $event)"
                                v-model="dinnerPriceFormatted"
                                dense></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- Extra Pakkets -->
                    <v-row no-gutters  v-if="extraSelected.find(x => x == `Breakfast`)">
                        <v-col cols="6" class="my-3">
                            Breakfast
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <v-text-field
                                prefix="€"
                                @keypress="isCurrency(breakfastPriceFormatted, $event)"
                                v-model="breakfastPriceFormatted"
                                dense></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row no-gutters v-if="extraSelected.find(x => x == `Hotel`)">
                        <v-col cols="6" class="my-3">
                            Hotel
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <v-text-field
                                prefix="€"
                                append-icon="mdi-close"
                                @keypress="isCurrency(hotelPriceFormatted, $event)"
                                v-model="hotelPriceFormatted"
                                @click:append="deleteExtra(`Hotel`)"
                                dense></v-text-field>
                            <v-spacer></v-spacer>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="mt-0 mb-5 pr-3">

                            <div @click="showOptions = !showOptions" class="extra-options pb-1" >
                                Voeg extra pakket toe
                                <v-icon class="pr-3">{{showOptions ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>  
                            </div>

                            <v-divider color="white"></v-divider>
                            <v-expand-transition>
                                <div v-show="showOptions" >
                                    <v-list flat dense class="pa-0">
                                        <v-list-item-group
                                            @change="showOptions = false"
                                            multiple
                                            v-model="extraSelected">
                                            <template v-for="(item, i) in extraPakkets">
                                                <v-list-item class="text-capitalize px-0 ml-5"
                                                    :key="`item-${i}`"
                                                    :value="item"
                                                    active-class="white--text">
                                                    <template v-slot:default="{ active }">
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="item"></v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-list-item-icon v-if="active">
                                                        <v-icon class="pr-2">mdi-check</v-icon>  
                                                    </v-list-item-icon>
                                                    </template>
                                                </v-list-item>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </div>
                            </v-expand-transition>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" class="py-0 display-1 mt-4">
                            Planning Deelnemers
                        </v-col>
                        <v-col cols="12">
                            <Planning :plans="roadtrip.participantsItinerary" />
                            
                        </v-col>
                    </v-row>
                    <!-- Hide for now, handle later -->
                    <!-- <v-row no-gutters>
                        <v-col cols="12" class="py-0 display-1">
                            Planning Organisatie
                        </v-col>
                        <v-col cols="12">
                            <Planning :plans="roadtrip.organizationItinerary" />
                        </v-col>
                    </v-row> -->
                    <v-row no-gutters>
                        <v-col cols="12" class="py-0 display-1 mt-4">
                            OsmAnd- en GPX-informatie
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Upload GPX File
                        </v-col>
                         <v-col cols="6" class="ma-0 pa-0">
                            <v-file-input
                            class="pt-3"
                            show-size
                            counter
                            @change="onUploadGPX" :key="gpxInputKey"
                            label="Upload GPX-file"></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="my-3">
                            Distance
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <v-text-field
                                @keypress="isNumber($event)"
                                v-model.number="roadtrip.baseGPXInformation.distance"
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                     <v-row no-gutters v-show="!isNewMode">
                        <v-col cols="6" class="my-3">
                            Changes <span v-if="gpx_file != null">*</span>
                        </v-col>
                        <v-col cols="6" class="ma-auto pa-0">
                            <v-text-field
                                v-model="gpx_file_changes"
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" class="">
                            <OsmAndInformations :osmAndInfos="roadtrip.baseGPXInformation.osmAndInformation"  />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" class="display-1 pb-2 mt-4">
                            WhatsApp
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" class="ma-auto display-flex pa-0">
                            <v-text-field
                                label="Link *"
                                v-model="roadtrip.whatsAppURL"
                                :rules="[rules.required]"
                                required 
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <div class="fixed-bottom-section">
            <v-container>
                <v-row>
                    <v-col class="12 pt-2 mx-2">
                        <v-btn color="primary" block @click.stop="save()" :disabled="isLoading" >
                        Opslaan
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
 
</template>

<script>
import Planning from '@/components/admin/roadtrip/Planning';
import EditableInput from '@/components/common/EditableInput';
import EditableTextEditor from '@/components/common/EditableTextEditor';
import OsmAndInformations from '@/components/admin/roadtrip/OsmAndInformations';
import RoadtripLabelDropdown from '@/components/common/options/RoadtripLabelDropdown';
import ImageUploadCarousel from '@/components/common/ImageUploadCarousel';

import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";
import GlobalMixin from "@/mixins/global.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import RoadtripValidations from "@/mixins/validations/roadtrip-validations.mixin";

export default {
    name: 'ProductInformation',
    components: 
    {
        Planning,
        EditableInput,
        EditableTextEditor,
        OsmAndInformations,
        RoadtripLabelDropdown,
        ImageUploadCarousel
    },
    props: 
    {
        roadtrip: Object,
        isLoading: Boolean
    },
    mixins: [DataMixin, FormatMixin, MenuHelperMixin, RoadtripValidations, GlobalMixin],
    watch: {
        roadtrip: {
            deep: true,
            handler() {
                this.updateRoadtripObject();
            }
        },
    },
    data: (vm) => ({
       deleteIcon: require('@/assets/icons/delete.svg'),
        extraPakkets: [
            'Breakfast',
            'Hotel'
        ],
        gpxInputKey: 0,
        gpx_file: null,
        gpx_file_changes: '',
        extraSelected: [],
        isNewMode: true,
        showOptions: false,
        date: vm.getToday(),
    }),
    computed: {
     
        mainPriceFormatted: {
            get() {
                return this.getLocalNumericDisplay(this.roadtrip.productInformation.mainPrice);
            },
            set(newValue) {
                this.roadtrip.productInformation.mainPrice = this.localParseFloat(newValue);
            }
        },
        passengerPriceFormatted: {
            get() {
                return this.getLocalNumericDisplay(this.roadtrip.productInformation.passengerPrice);
            },
            set(newValue) {
                this.roadtrip.productInformation.passengerPrice = this.localParseFloat(newValue);
            }
        },
        breakfastPriceFormatted: {
            get() {
                return this.getLocalNumericDisplay(this.roadtrip.productInformation.breakfastPrice);
            },
            set(newValue) {
                this.roadtrip.productInformation.breakfastPrice = this.localParseFloat(newValue);
            }
        },
        lunchPriceFormatted: {
            get() {
                return this.getLocalNumericDisplay(this.roadtrip.productInformation.packedLunchPrice);
            },
            set(newValue) {
                this.roadtrip.productInformation.packedLunchPrice = this.localParseFloat(newValue);
            }
        },
        dinnerPriceFormatted: {
            get() {
                return this.getLocalNumericDisplay(this.roadtrip.productInformation.dinnerPrice);
            },
            set(newValue) {
                this.roadtrip.productInformation.dinnerPrice = this.localParseFloat(newValue);
            }
        },
        hotelPriceFormatted: {
            get() {
                return this.getLocalNumericDisplay(this.roadtrip.productInformation.hotelPrice);
            },
            set(newValue) {
                this.roadtrip.productInformation.hotelPrice = this.localParseFloat(newValue);
            }
        }
    },
    beforeCreate(){
        this.$store.dispatch('menuHelper/getProductStatuses');
        this.$store.dispatch('menuHelper/getRoadtripLabels');
        this.$store.dispatch('menuHelper/getRoadtripLocationTypes');
        this.$store.dispatch('menuHelper/getCountries');
    },
    methods: {
        updateRoadtripObject()
        {
            if (!this.roadtrip) return;

            if (this.roadtrip.productInformation.hotelPrice > 0)
            {
                this.extraSelected.push('Hotel');
            }

            this.isNewMode = this.roadtrip.roadtripId == "";
        },
        updateTitle(title)
        {
           this.roadtrip.productInformation.name = title;
        },
        updateProductText(text)
        {
           this.roadtrip.productInformation.productText = text;
        },
        updatePakketDescription(text)
        {
           this.roadtrip.productInformation.packageDescription = text;
        },
        deleteExtra(extra)
        {
            this.extraSelected = this.extraSelected.filter(item => item !== extra)
        },
        onUploadGPX(file)
        {
            if (file != null && !file.name.endsWith('.gpx'))
            {
                this.$store.commit('dialog/showDialog', 'Bestandstype moet gpx zijn.');
                this.gpxInputKey++; // clear file input in browser
                return;
            }

            this.gpx_file = file;
        },
        onImageAdded()
        {
            // check for total image size to be uploaded.
            // must not exceed allowed: 100MB
            let errors = this.checkRoadtripImageSize(this.getNewImages());
            if(errors != "")
            {
                this.$store.commit('dialog/showDialog', errors);
            }
        },
        save()
        {
            const status = this.roadtrip.isPublished ? this.getProductStatusId("Published") 
                            : this.getProductStatusId("Private");
            this.roadtrip.productInformation.productStatus = status;
            this.roadtrip.gpx_file = this.gpx_file;
            this.roadtrip.gpx_file_changes = this.gpx_file_changes;

            let errors = this.checkNewRoadtrip(this.roadtrip);
            errors = errors + this.checkRoadtripImageSize(this.getNewImages());
            if(errors != "")
            {
                this.$store.commit('dialog/showDialog', errors);
                return;
            }

            this.$emit('save');
        },
        getNewImages()
        {
            return this.roadtrip.images.filter(x => x.file != null).map(x => x.file);
        }
    },
}
</script>
