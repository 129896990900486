<template>
    <div class="upload-carousel-container">
        <input type="file" ref="file" style="display: none" accept="image/*" multiple @change="previewFile">
        <v-carousel
        :continuous="false"
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        height="350">
            <v-carousel-item
            v-for="(image, i) in images"
            :key="i">
                <v-sheet
                color="#0071E3"
                height="100%"
                tile>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-img
                            v-if="image.photoURL"
                            :src="image.photoURL"
                            style="object-fit: cover; height: 350px;" />
                         <v-btn v-if="image.photoURL"
                            outlined 
                            fab
                            dark
                            elevation="2"
                            @click="removeImage(i)"
                            class="mx-2 remove-image"
                            x-small><v-icon>mdi-close</v-icon></v-btn>    
                        <ImageRedirect v-if="!image.photoURL" class="upload-image" :imageSource="uploadIcon" @imgClick="addImage(i)" />
                    </v-row>
                   
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
import { Photo } from '@/models/Roadtrip/Photo';
import ImageRedirect from '@/components/common/ImageRedirect';
export default {
    name: 'ImageUploadCarousel',
    props: {
        images: Array
    },
    components: {
        ImageRedirect
    },
    data () {
        return {
            uploadIcon: require('@/assets/icons/upload-photos.svg'),
            colors: [
                '#0071E3',
                'secondary',
                'yellow darken-4',
                'red lighten-2',
                'orange darken-1',
            ],
            cycle: false,
            filename: '',
            photoURL: null,
            currentSlideIndex: 0
        }
    },
    methods: {
        addImage(index)
        {
            this.currentSlideIndex = index;
            this.resetFileSelected();
            this.$refs.file.click();
        },
        resetFileSelected()
        {
            if (this.$refs.file != null)
            {
                this.$refs.file.value = null;
            }
        },
        previewFile()
        {
            Array.from(this.$refs.file.files).forEach( (file) => {
                let currentSlide = this.images[this.currentSlideIndex];
                currentSlide.file = file;
                this.onFileChange(currentSlide);
                this.currentSlideIndex++;
            });

            this.onImagesAdded();
        },
        onFileChange(currentSlide) {
            //const file = payload.target.files[0]; // use it in case of normal HTML input
            const file = currentSlide.file; // in case vuetify file input
            if (file) {
                currentSlide.photoURL = URL.createObjectURL(file);
                URL.revokeObjectURL(file); // free memory
                this.images.push(new Photo());
            } else {
                currentSlide.photoURL =  null
            }
        },
        removeImage(index)
        {
            this.$delete(this.images, index);
        },
        onImagesAdded()
        {
            this.$emit('added');
        }
    }
}
</script>
<style>
.upload-carousel-container .v-carousel
{
    background-color: #0071E3;
}

.upload-image {
    cursor: pointer;
}

.upload-carousel-container .v-image__image--cover {
    background-color: #0071E3;
}

.upload-carousel-container .hoverable-img.upload-image
{
    box-shadow: none !important;
    background-color: #0071E3;
    border-color: none !important;
}

.upload-image .v-image__image--cover
{
    background-size: contain !important;
}

.upload-carousel-container .hover-image-overlay.v-overlay
{
    background: transparent;
}

.upload-carousel-container .v-image.grey.darken-4 {
    background-color: transparent!important;
    border-color: transparent!important;
}

.upload-carousel-container .hover-image-overlay .v-overlay__scrim
{
    background-color: #0071E3 !important;
    border-color: #0071E3 !important;
}

.remove-image
{
    position: absolute;
    top: 15px;
    right: 8px;
    text-align: center;
    background: #00000036;
}
</style>