<template>
    <div>
       <div v-for="(osm,index) in osmAndInfos"
                :key="index">
                <OsmAndInformationTemplate :indexNr="index" :osmAndInfos="osmAndInfos" />
        </div>
        <div class="new-osmAndInfos">
            <v-expand-transition>
                <div v-show="pendingPlan" >
                    <OsmAndInformationTemplate :indexNr="osmAndInfos.length + 1" :osmAndInfos="osmAndInfos" />
                </div>
            </v-expand-transition>
            <div class="add-osmAndInfos" :class="{ 'hide' :pendingPlan }">
                <v-btn color="primary" block class="mt-2 mb-5"  @click="addPlan" >
                    Voeg nieuwe OsmAnd Informatie toe
                </v-btn>
            </div>
    </div>
</div>
 
</template>

<script>

import OsmAndInformationTemplate from '@/components/admin/roadtrip/OsmAndInformationTemplate'
export default {
    name: 'OsmAndInformations',
    components: 
    {
        OsmAndInformationTemplate,
    },
    data () {
        return {
            deleteIcon: require('@/assets/icons/delete.svg'),
        }
    },
    computed:
    {
        pendingPlan: function()
        {
            return this.osmAndInfos.find(x => x.isPending);
        }
    },
    props: 
    {
        osmAndInfos: Array
    },
    methods: {
        addPlan()
        {
            this.osmAndInfos.push({
                isPending: true,
                mapName: '',
                country: 0
            });
        },
    },
}
</script>

<style>
.new-osmAndInfos .v-expansion-panel-content__wrap
{
    padding: 0px;
    background: #181717;
}

.hide
{
    visibility: hidden !important;
    opacity: 0 !important;
    height: 0 !important;
    overflow: hidden;
    transition: height 0ms 400ms, opacity 400ms 0ms !important;
}

.new-osmAndInfos .add-osmAndInfos
{
    visibility: visible;
    opacity:1;
    height: auto;
    transition: height 0ms 0ms, opacity 600ms 0ms;
}

</style>