<template>
    <div>
         <v-expand-transition>
        <div v-if="osmAndInfo">
            <v-form ref="form" class="my-2"
                        v-model="valid"
                        lazy-validation>
                <v-row no-gutters>
                   <v-col cols="6" class="ma-auto pa-0">
                        <v-text-field
                            label="Map Naam"
                            :rules="[rules.required]"
                            required
                            :readonly="!isPending"
                            v-model="osmAndInfo.mapName"
                            dense></v-text-field>
                    </v-col>
                    <template v-if="isPending">
                        <v-col cols="6" class="pl-3">
                            <CountryDropdown :country="osmAndInfo.country" :readonly="!isPending" @changed="osmAndInfo.country = $event"/>
                        </v-col>
                    </template>
                    <template v-if="!isPending">
                        <v-col cols="5" class="pl-3">
                            <CountryDropdown :country="osmAndInfo.country" :readonly="!isPending" @changed="osmAndInfo.country = $event"/>
                        </v-col>
                        <v-spacer></v-spacer>
                        <div v-if="!isPending" class="text-end">
                            <img class="delete-icon" :src="deleteIcon" alt="Delete" @click="deleteItem()" />
                        </div>
                    </template>
                </v-row>
            </v-form>

            <div v-show="isPending">
                    <v-btn color="primary" block class="mt-2"  @click="saveItem">
                    Opslaan
                </v-btn>
                <v-col cols="12 text-center">
                    Geen locatie toevoegen?
                    <span class="link" @click="removeItem()">Annuleren.</span>
                </v-col>
            </div>
        </div>
         </v-expand-transition>
    </div>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
import GlobalMixin from "@/mixins/global.mixin";
import CountryDropdown from "@/components/common/options/CountryDropdown";
export default {
    name: 'OsmAndInformationTemplate',
    components: {
        CountryDropdown
    },
    data () {
        return {
            deleteIcon: require('@/assets/icons/delete.svg'),
            valid: true,
        }
    },
    props: 
    {
        osmAndInfos: Array,
        indexNr: Number,

    },
    computed: {
        osmAndInfo: function () {
            return this.osmAndInfos[this.indexNr];
        },
        isPending: function()
        {
            var isPending = this.osmAndInfo ? this.osmAndInfo.isPending : true;
            return isPending;
        },
        disableMoveDown: function () {
            let last = this.osmAndInfos.filter(x => x.isPending == false).pop();
            var index = this.osmAndInfos.indexOf(last);
            return this.indexNr == index;
        },
    },
    mixins: [GlobalMixin, DataMixin],
    methods: {
        saveItem()
        {
            const valid = this.$refs.form.validate()
            if (valid)
            {
                this.osmAndInfo.isPending = false;
            }
        },
        removeItem()
        {
            this.osmAndInfos.pop();
        },
        deleteItem()
        {
            this.$delete(this.osmAndInfos, this.indexNr);
        }
    },
}
</script>

<style>
.location-header
{
    display: inline-flex;
    position: relative;
}

.location-header .hide
{
    width: 0px;
}

.arrow-up-icon,
.arrow-down-icon
{
    width: 25px;
    height: 25px;
}

.delete-icon 
{
    width: 20px;
    height: 20px;
    margin: 3px;
}
</style>