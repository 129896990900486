<template>
    <div>
        <v-select 
            v-model="txt_locationType"
            :items="roadtripLocationTypes"
            offset-y
            item-text="item2"
            item-value="item1"
            :rules="[rules.required]"
            required
            :readonly="txt_isReadOnly"
            placeholder="Kies type"
            dense></v-select>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DataMixin from "@/mixins/data.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
export default {
    name: 'RoadtripLocationDropdown',
    props: 
    {
        locationType: Number,
        isReadOnly: Boolean,
    },
    mixins: [DataMixin, MenuHelperMixin],
    beforeMount()
    {
        this.txt_locationType = this.locationType;
        this.txt_isReadOnly = this.isReadOnly  ? this.isReadOnly : false;
    },
    watch: {
        locationType: {
            deep: true,
            handler() {
                this.txt_locationType = this.locationType;
            }
        },
        isReadOnly: {
            deep: true,
            handler() {
                this.txt_isReadOnly = this.isReadOnly;
            }
        },
        txt_locationType: 'onValueChanged'
    },
    data() {
        return {
            txt_locationType: {},
            txt_isReadOnly: false,
        }
    },
    computed: {
        ...mapState({
            roadtripLocationTypes: state => state.menuHelper.roadtripLocationTypes
        })
    },
    methods:
    {
        onValueChanged()
        {
            this.$emit('changed', this.txt_locationType);
        }
    }
}
</script>